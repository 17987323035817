<template>
    <HomeContentLayout>
        <div class="main_back">           
            
            <img style="width:350px;height:90px;" src="@/assets/title.png" />
            <div class="step">
                 {{fldObj['fd_pwd']}}
            </div>
            <div style=" margin-top:10px ">
                <el-steps :active="active" align-center space="100%">
                    <el-step :title="fldObj['fd_accno']" icon="el-icon-user-solid"></el-step>
                    <el-step :title="fldObj['fd_auth']" icon="el-icon-s-check"></el-step>
                    <el-step :title="fldObj['fd_reset']" icon="el-icon-setting"></el-step>
                </el-steps>
            </div>
 
            <div :style="display"  >
                <el-input :placeholder="fldObj['fd_mail']"  v-model="mailaddr"  style="width:450px; ">
                    <div slot="prepend" style="width:120px; " >{{fldObj['fd_addr']}}</div> 
                </el-input>
                <div style="margin-top:25px;">
                    <el-button type="primary" @click="nextStep" icon="iconfont icon-xiayiye1" style="width:450px; ">{{fldObj['fd_next']}}</el-button>
                </div>
            </div>
            <div :style="display2" > 
                <div style="margin-top:25px;">              
                    <el-input  v-model="mailaddr"  style="width:380px;margin-left:10px;" disabled></el-input> 
                </div>
                <div style="margin-top:25px;  "> 
                    <el-input :placeholder="fldObj['fd_code']" v-model="form.code2"   style="width:380px; ">
                        <div style="width:120px;text-align:center" slot="append">
                            <el-button :disabled="disabled"  type="primary"  @click="sendCode" style="color:#19be6b;">{{mailBtn}}</el-button>
                        </div>
                    </el-input>
   
                </div>              
                <div style="margin-top:35px; ">
                    <el-button type="primary" @click="messageChk" icon="iconfont icon-xiayiye1" style="width:380px; ">{{fldObj['fd_next']}}</el-button>
                </div>
            </div>
            <div :style="display3" >                                                                    
 
 
                <div style="margin-top:20px;">
                    <el-input
                        type="password"
                        :placeholder="fldObj['fd_new']"
                        autofocus=true
                        v-model="form.password"
                        style="width:350px;margin-left:10px;"
                    >
                        <el-button slot="prepend" icon="el-icon-lock" style="background:#F2F6FC"></el-button>
                    </el-input>
                </div>
                <div style="margin-top:30px;">
                    <el-input
                        type="password"
                        :placeholder="fldObj['fd_cfmnew']"
                        v-model="form.cfmpassword"
                        style="width:350px;margin-left:10px;"
                    >
                        <el-button slot="prepend" icon="el-icon-lock" style="background:#F2F6FC"></el-button>
                    </el-input>  
                </div>            
                <div style="margin-top:25px;">
                    <el-button type="primary" @click="nextStep3" style="width:350px;margin-left:10px;" icon="iconfont icon-baocun">{{fldObj['fd_save']}}</el-button>
                </div>
            </div>
            <el-dialog v-dialogDrag :visible.sync="promptWin" width="450px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
                <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                    <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">提示</span>
                </div>
                <div   >{{prompt}} </div>
                <span slot="footer"  :style="{'height':'40px' }">
                    <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        
    </HomeContentLayout>
</template>

<script>
import {getBaseData} from '../../api/user'
    export default {
        name: "ForgetPwd",
        
        data () {
            return {
                active: 1,
                LANG:'CN',
                disabled:false,
                auth_time: 0, /*倒计时 计数器*/
                mailaddr:'',
                promptWin:false,
                prompt:'',
                area:'',
                mailBtn:'',
                validmsg:'',
                fldObj:{},
                form:{
                    userno:'',
                    password:'',
                    cfmpassword:'',
                    code2:'',
                },
                display :'margin-top:30px;display:block',
                display2 :'margin-top:30px;display:none',
                display3 :'margin-top:30px;display:none',
                tabCol:[],
            }
        },
        watch: {
 
        },
        mounted() {
            //国家代号
           getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
                this.tabCol=res.data.result
            })
            this.area='305'
            
            this.getField()
        },
        methods: {
            //邮箱验证码
            sendCode(){
                this.$axios({   
                    method: 'post',
                    url: this.$store.state.baseUrl+'auth/login/getBaseData',
                    data: {p_key:'idseq',p_value:'57238',p_table:'v_CONTENT_SET'},
                }).then(res=>{ 
                    let v_title=this.LANG=='CN'?res.data.result[0]['TITLE']:res.data.result[0]['ETITLE']
                    let v_note=this.LANG=='CN'?res.data.result[0]['NOTE']:res.data.result[0]['ENOTE']
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'auth/login/sendMail',
                        data: {p_user:'mailreg',mailaddr:this.mailaddr,title:v_title,p_note:v_note},
                    }).then(res=>{      
                        if(res.data.code ==='200')  {      
    
                            this.promptWin =true
                            this.prompt =this.fldObj['fd_send']
                            this.disabled = true
                            this.auth_time = 60
                            var auth_timetimer =  setInterval(()=>{
                                this.auth_time--;
                                this.mailBtn=this.auth_time+this.fldObj['fd_resend']
                                if(this.auth_time<=0){
                                    this.disabled = false;
                                    this.mailBtn= this.fldObj['fd_get']
                                    clearInterval(auth_timetimer);
                                }
                            }, 1000);
                        }else{
                            this.promptWin =true
                            this.prompt =res.data.message
                        }       
                    }) 
                })
            },
            //界面字段语言设置
            getField(){
                let that =this
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/getBaseData',
                    data: {p_key:'idseq', p_value:'56405',p_table:'V_BS_PAGEDTL'},
                }).then(res=>{ 
                    for(let k=0 ;k<res.data.result.length; k++){
                        that.fldObj[res.data.result[k]['SUBNO']] =that.LANG=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                    }
                    that.$forceUpdate()
                    this.mailBtn =this.fldObj['fd_get']
                })        
            },
 
            nextStep(){     
                var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
                if (!verify.test(this.mailaddr )) {
                    this.$Message.error(this.fldObj['fd_mailchk'])
                }else{  
                    this.$axios({
                        method:'post', 
                        url: this.$store.state.baseUrl+'auth/login/getBaseData' ,
                        data:{ p_key :'trim(mailaddr)',p_value: this.mailaddr.trim() , p_table:'v_sys_user'},
                    }).then(res =>{ 
                        if (res.data.result.length>0){                        
                            this.active =this.active+1;
                            if (  this.active ===1) {
                                this.display= 'margin-top:30px;display:block'
                            }else{
                                this.display= 'margin-top:30px;display:none'
                                this.display2= 'margin-top:30px;display:block'
                            }
                        }else{
                            this.$Message.error(this.fldObj['fd_notbind'])
                        }
                    })
                } 
            },
            messageChk(){
                let  that =this
                if (!this.form.code2){
                    this.$Message.error(this.fldObj['fd_msgchk'])
                } else {
                    //短信验证码 10分钟有效 及合法性判断
                    that.$axios({
                        method:'post', 
                        url: that.$store.state.baseUrl+'auth/login/getBaseData' ,
                        data:{  //get这里应为params //请求参数
                            p_key :'mailaddr',
                            p_value: that.mailaddr,
                            p_key2:'msgno',
                            p_value2 :that.form.code2,
                            p_table:'V_BS_MSG',
                        },
                    }).then(res =>{                  
                        if (res.data.code === '200'){
                            if (res.data.result.length>0){
                                this.active =this.active+1;
                                this.display2= 'margin-top:30px;display:none'
                                this.display3= 'margin-top:30px;display:block'     
                            }else{
                                this.$Message.error(this.fldObj['fd_valid'])
                            }
                        }else{
                            this.$Message.error(res.data.message)
                        }
                    }) 

                }
                
            },
            nextStep3(){
                //修改密码
                let vm=this     
                let pwd_ =this.form.password
                let cfmpwd_=this.form.cfmpassword
                if (!pwd_){
                    this.$Message.error(this.fldObj['fd_new'])
                }else if (!cfmpwd_){
                    this.$Message.error( this.fldObj['fd_cfmnew'])
                }else{
                    if(pwd_.length<6){
                        this.$Message.error(this.fldObj['fd_new'])
                    }else if(cfmpwd_.length<6){
                        this.$Message.error(this.fldObj['fd_cfmnew'])
                    }else if(pwd_!==cfmpwd_){
                        this.$Message.error(this.fldObj['fd_match'])
                    }else{
                        vm.$axios({
                            method: 'post',
                            url:vm.$store.state.baseUrl+'auth/login/updatePassword',
                            data: {p_value: this.mailaddr, p_table:'sys_user',p_value2:pwd_},
                        }).then(res=>{      
                            if(res.data.result ==='OK')  {          
                                this.$router.replace('/');
                            }else{
                                this.promptWin =true
                                this.prompt =res.data.result
                            }        
                        }) 
                    }         
                }         
            }
        }
    }
</script>

<style scoped>

.step{
    color:rgb(94, 94, 153);
    font-size:18px;
    font-weight:600; 
    margin-top:15px;
    margin-bottom: 5px;
    padding-left:10px;
    text-align: left;
    line-height:40px;
    background:#f7f8f9;
    border-left:4px solid #409eff;
}
.rowline{
    width:250px;
    margin-left:10px;
    margin-top:20px;
}
.main_back{
 
    margin:100px auto;  
    width:50%;
    border:1px solid #fff;
    height:500px;
    text-align:center;
}
</style>
